import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-delete-tenant-dialog',
  templateUrl: './delete-tenant-dialog.html'
})
export class DeleteTenantDialogComponent {

  public tenant;

  constructor(
    public dialogRef: MatDialogRef<DeleteTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tenant = data.tenant;
  }

  public deleteTenant(tenant) {
    this.dialogRef.close(tenant);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
