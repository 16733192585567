import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-edit-tenant-social-media-link-dialog',
  templateUrl: './edit-tenant-social-media-link-dialog.component.html'
})
export class EditTenantSocialMediaLinkDialogComponent {

  public socialMediaLinks: any;

  constructor(
    public dialogRef: MatDialogRef<EditTenantSocialMediaLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.socialMediaLinks = data.socialMediaLinks;
  }

  public updateSocialMediaLinks() {
    this.dialogRef.close(this.socialMediaLinks);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

}
