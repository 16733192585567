import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as config from '../../../config/config.json';
import { IAuditLogs } from '../interfaces/audit-log.interface';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(private http: HttpClient) { }

  private api = `${config.api.tenantManager}/logs`;

  public getLogs(): Observable<IAuditLogs> {
    return this.http.get<IAuditLogs>(this.api);
  }

}
