export const LEGAL_VALUE_TYPE_LINK = 'LINK';
export const LEGAL_VALUE_TYPE_HTML = 'HTML';

export const LEGAL_VALUES_TYPES = [{
  label: 'Link',
  value: LEGAL_VALUE_TYPE_LINK
}, {
  label: 'Html',
  value: LEGAL_VALUE_TYPE_HTML
}];
