import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTableModule
} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { TenantSocialMediaLinkFormComponent } from './tenant-social-media-link-form.component';
import {
  AddTenantSocialMediaLinkDialogComponent
} from './add-tenant-social-media-link-dialog/add-tenant-social-media-link-dialog.component';
import {
  EditTenantSocialMediaLinkDialogComponent
} from './edit-tenant-social-media-link-dialog/edit-tenant-social-media-link-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule
  ],
  declarations: [
    TenantSocialMediaLinkFormComponent,
    AddTenantSocialMediaLinkDialogComponent,
    EditTenantSocialMediaLinkDialogComponent
  ],
  entryComponents: [
    AddTenantSocialMediaLinkDialogComponent,
    EditTenantSocialMediaLinkDialogComponent
  ],
  exports: [
    TenantSocialMediaLinkFormComponent
  ]
})
export class TenantSocialMediaLinkFormModule { }
