import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterBarModule } from './footer-bar/footer-bar.module';
import { MainBarModule } from './main-bar/main-bar.module';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { MainBarComponent } from './main-bar/main-bar.component';

@NgModule({
  imports: [
    CommonModule,
    MainBarModule,
    FooterBarModule
  ],
  declarations: [],
  exports: [
    FooterBarComponent,
    MainBarComponent
  ]
})
export class NavigationModule { }
