export const configLoader = new Promise<any>((resolve, reject) => {
  const XMLHttp = new XMLHttpRequest();
  const conf =  './config/config.json';

  try {
    XMLHttp.open('GET', conf, true);
    XMLHttp.onload = function() {
      if (XMLHttp.status === 200) {
        resolve(JSON.parse(XMLHttp.responseText));
      } else {
        reject();
      }
    };
    XMLHttp.send();
  } catch (error) {
    reject(error);
  }
});
