import { Routes } from '@angular/router';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { TenantsComponent } from './tenants/tenants.component';
import { TenantDetailsComponent } from './tenants/tenant-details/tenant-details.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: TenantsComponent,
    data: { title: 'Tenant List' }
  }, {
    path: 'detail/:id',
    component: TenantDetailsComponent
  }, {
    path: 'logs',
    component: AuditLogsComponent
  }, {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];
