import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../shared/service/snackbar.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
  Title,
} from '@angular/platform-browser';
import { TenantService } from '../service/tenant.service';
import { ITenant } from '../interfaces/tenant.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  deepMerge,
  saveCopyObject,
} from '../../shared/helper/deep-merge.helper';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.css'],
})
export class TenantDetailsComponent implements OnInit {
  public tenantId: string;
  public tenant: ITenant;

  public stepsAreLinear = false;
  public generalFormGroup: FormGroup;
  public themeFormGroup: FormGroup;
  public legalFormGroup: FormGroup;
  public contactFormGroup: FormGroup;
  public socialMediaLinksFormGroup: FormGroup;

  public themes;
  public versions: string[] = [];
  public safeThemePreviewUri: SafeResourceUrl;
  public safeThemeLogoUri: SafeResourceUrl;
  public safeThemeFaviconUri: SafeResourceUrl;
  private safeFallBackThemeFaviconUri: SafeResourceUrl;

  public loading = true;
  public error = false;

  public themePanelOpen = false;

  public loadingTheme = true;
  public errorTheme = true;

  public themesLoading = true;
  public themesError = false;

  public saveCopyObject = saveCopyObject;

  private previewHtmlSrc = 'assets/demo-page/index.html';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly snackbarService: SnackbarService,
    private readonly tenantService: TenantService,
    private readonly titleService: Title,
    private readonly formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {}

  public setTenant(id) {
    this.tenantService.getTenant(id).subscribe(
      (tenant: ITenant) => {
        this.tenant = tenant;
        this.setTenantData(tenant);
        this.loading = false;
        this.error = false;
      },
      () => {
        this.loading = false;
        this.error = true;
      }
    );
  }

  public updateTenant() {
    this.tenantService.updateTenant(this.tenant).subscribe(
      () => {
        this.snackbarService.open(`${this.tenantId} is now up to date`);
      },
      () => {
        this.snackbarService.open(`${this.tenantId} cannot be updated`);
      }
    );
  }

  private setGeneralFormControlConfig(tenant: ITenant) {
    this.generalFormGroup = this.formBuilder.group({
      id: [tenant.id, Validators.required],
      locales: [tenant.locales],
      company: [tenant.company, Validators.required],
      defaults: this.formBuilder.group({
        locale: [tenant.defaults.locale, Validators.required],
        energyManagerName: [tenant.defaults.energyManagerName],
        centricsId: [tenant.defaults.centricsId],
      }),
    });
    this.generalFormGroup.markAllAsTouched();
  }

  public saveGeneralInformation() {
    if (this.generalFormGroup.dirty) {
      this.tenant = deepMerge(
        this.tenant,
        saveCopyObject(this.generalFormGroup.value)
      );
      this.generalFormGroup.markAsPristine();
      this.updateTenant();
    }
  }

  public setThemes(tenant: ITenant) {
    this.themesLoading = false;
    this.themesError = false;
    this.tenantService.getThemes().subscribe(
      (response) => {
        this.themes = response.items;
        if (tenant.theme) {
          this.setThemeVersions(tenant.theme.id, this.themes);
        }
        this.themesLoading = false;
        this.themesError = false;
      },
      () => {
        this.themesLoading = false;
        this.themesError = true;
      }
    );
  }

  private setThemeVersions(themeId: string, themes: any[]) {
    if (themeId && themes) {
      const foundTheme = themes.find((item) => {
        return item.id === themeId;
      });
      if (foundTheme && foundTheme.versions) {
        this.versions = foundTheme.versions;
      }
    }
  }

  private setThemeFormControlConfig(tenant: ITenant) {
    this.themeFormGroup = this.formBuilder.group({
      id: [tenant.theme.id, Validators.required],
      version: [tenant.theme.version, Validators.required],
    });
  }

  public changeTheme(event: MatSelectChange) {
    this.setThemeVersions(event.value, this.themes);
    this.themeFormGroup.get('version').patchValue('');
  }

  public saveThemeInformation() {
    if (this.themeFormGroup.dirty) {
      this.tenant.theme = deepMerge(
        this.tenant.theme,
        saveCopyObject(this.themeFormGroup.value)
      );
      this.themeFormGroup.markAsPristine();
      this.updateTenant();
    }
  }

  public localesChanged(locales: string[]) {
    this.generalFormGroup.get('locales').patchValue(locales);
    this.generalFormGroup.markAsDirty();
    const defaultLocaleControl = this.generalFormGroup.get('defaults.locale');
    if (locales.indexOf(defaultLocaleControl.value) === -1) {
      defaultLocaleControl.patchValue('');
    }
  }

  private setLegalFormControlConfig(tenant: ITenant) {
    this.legalFormGroup = this.formBuilder.group({
      imprint: [saveCopyObject(tenant.legal.imprint)],
      privacy: [saveCopyObject(tenant.legal.privacy)],
      termsOfUse: [saveCopyObject(tenant.legal.termsOfUse)],
    });
  }

  public getLegalTabNote(legal: string): string | null {
    let additionalNote = null;
    const values = this.legalFormGroup.get(legal).value || [];
    if (values && values.length === 0) {
      additionalNote = 'No entry is set…';
    } else if (
      !values.find((item) => item.locale === this.tenant.defaults.locale)
    ) {
      additionalNote = 'No entry for default locale is set…';
    }
    return additionalNote;
  }

  public legalChanged(value, unit) {
    this.legalFormGroup.get(unit).patchValue(value);
    this.legalFormGroup.markAsDirty();
  }

  public saveLegalInformation() {
    if (this.legalFormGroup.dirty) {
      this.tenant.legal = saveCopyObject(this.legalFormGroup.value);
      this.legalFormGroup.markAsPristine();
      this.updateTenant();
    }
  }

  private setContactFormControlConfig(tenant: ITenant) {
    this.contactFormGroup = this.formBuilder.group({
      contact: [saveCopyObject(tenant.legal.contact)],
    });
  }

  public contactChanged(value) {
    this.contactFormGroup.get('contact').patchValue(value);
    this.contactFormGroup.markAsDirty();
  }

  public saveContactInformation() {
    if (this.contactFormGroup.dirty) {
      this.tenant.legal.contact = saveCopyObject(
        this.contactFormGroup.get('contact').value
      );
      this.contactFormGroup.markAsPristine();
      this.updateTenant();
    }
  }

  private setSocialLinksFormControlConfig(tenant: ITenant) {
    this.socialMediaLinksFormGroup = this.formBuilder.group({
      socialMediaLinks: [saveCopyObject(tenant.socialMediaLinks)],
    });
  }

  public saveSocialLinksInformation() {
    if (this.socialMediaLinksFormGroup.dirty) {
      this.tenant.socialMediaLinks = saveCopyObject(
        this.socialMediaLinksFormGroup.get('socialMediaLinks').value
      );
      this.socialMediaLinksFormGroup.markAsPristine();
      this.updateTenant();
    }
  }

  public socialMediaLinksChanged(value) {
    this.socialMediaLinksFormGroup.get('socialMediaLinks').patchValue(value);
    this.socialMediaLinksFormGroup.markAsDirty();
  }

  public setThemePreviewUri() {
    this.loadingTheme = true;
    this.errorTheme = false;
    const id = this.themeFormGroup.get('id').value;
    const version = this.themeFormGroup.get('version').value;
    if (id && version) {
      this.tenantService.getThemeByVersion(id, version).subscribe((theme) => {
        const themePreviewUri = `${
          this.previewHtmlSrc
        }?themeMinCssUri=${encodeURI(theme.cssMinUri)}`;
        const safeThemeLogoUri = `${theme.baseUri}images/logo.svg`;
        const themeFaviconUri = `${theme.baseUri}images/favicons/android-chrome-512x512.png`;
        const themeFallbackFaviconUri = `${theme.baseUri}images/favicons/android-chrome-256x256.png`;
        this.safeThemePreviewUri =
          this.sanitizer.bypassSecurityTrustResourceUrl(themePreviewUri);
        this.safeThemeLogoUri =
          this.sanitizer.bypassSecurityTrustResourceUrl(safeThemeLogoUri);
        this.safeThemeFaviconUri =
          this.sanitizer.bypassSecurityTrustResourceUrl(themeFaviconUri);
        this.safeFallBackThemeFaviconUri =
          this.sanitizer.bypassSecurityTrustResourceUrl(
            themeFallbackFaviconUri
          );
        this.loadingTheme = false;
      });
    } else {
      this.loadingTheme = false;
      this.errorTheme = true;
    }
  }

  public setThemeFaviconUriFallbackUri() {
    this.safeThemeFaviconUri = this.safeFallBackThemeFaviconUri;
  }

  private setTenantData(tenant: ITenant) {
    this.setThemes(tenant);
    this.setGeneralFormControlConfig(tenant);
    this.setThemeFormControlConfig(tenant);
    this.setLegalFormControlConfig(tenant);
    this.setContactFormControlConfig(tenant);
    this.setSocialLinksFormControlConfig(tenant);
    this.setThemePreviewUri();
  }

  ngOnInit() {
    this.tenantId = this.activatedRoute.snapshot.params['id'];
    this.titleService.setTitle(this.tenantId);
    this.setTenant(this.tenantId);
  }
}
