import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as config from '../../../config/config.json';
import * as _ from 'lodash';
import { TenantContactExample, TenantExample, TenantLegalUnitExample } from './tenant.examples';
import { IContactItem, ILegalItem, ITenant, ITenants, ITheme, IThemes } from '../interfaces/tenant.interface';

@Injectable()
export class TenantService {

  constructor(private http: HttpClient) { }

  public api = {
    tenants: `${config.api.tenantManager}/tenants`,
    themes: `${config.api.tenantManager}/themes`,
    meta: `${config.api.tenantManager}/meta`
  };

  public getTenant(id: String): Observable<ITenant> {
    return this.http.get<ITenant>(`${this.api.tenants}/${id}`);
  }

  public getTenants(): Observable<ITenants> {
    return this.http.get<ITenants>(this.api.tenants);
  }

  public addTenant(tenant: ITenant): Observable<ITenant> {
    return this.http.post<ITenant>(this.api.tenants, tenant);
  }

  public deleteTenant(tenant: ITenant): Observable<ITenant> {
    return this.http.delete<any>(`${this.api.tenants}/${tenant.id}`);
  }

  public updateTenant(tenant: ITenant): Observable<ITenant> {
    return this.http.put<ITenant>(`${this.api.tenants}/${tenant.id}`, tenant);
  }

  public getThemes(): Observable<IThemes> {
    return this.http.get<IThemes>(this.api.themes);
  }

  public getThemeByVersion(id: string, version: string): Observable<ITheme> {
    return this.http.get<ITheme>(`${this.api.meta}/${id}/${version}`);
  }

  public getTenantExample(): ITenant {
    return _.cloneDeep(TenantExample);
  }

  public getTenantLegalExample(locale = ''): ILegalItem {
    const tenantLegalItemExample = _.cloneDeep(TenantLegalUnitExample);
    tenantLegalItemExample.locale = locale;
    return tenantLegalItemExample;
  }

  public getTenantContactExample(locale = ''): IContactItem {
    const tenantContactExample = _.cloneDeep(TenantContactExample);
    tenantContactExample.locale = locale;
    return tenantContactExample;
  }
}
