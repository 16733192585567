import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { LOCALE_RECOMMENDATIONS } from './add-locale.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-add-locale',
  templateUrl: './add-locale.component.html',
  styleUrls: ['./add-locale.component.scss']
})
export class AddLocaleComponent implements OnInit, OnChanges {

  @Input() locales: string[] = [];

  @Output() localesChanged = new EventEmitter();

  public formControl = new FormControl();
  public options: string[] = LOCALE_RECOMMENDATIONS;
  public filteredOptions: Observable<string[]>;

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = this.options && this.options.length && this.locales && this.locales.length ?
      this.options.filter(option => this.locales.indexOf(option) === -1) : LOCALE_RECOMMENDATIONS;
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  public addLocale(event: MatAutocompleteSelectedEvent) {
    this.locales.push(event.option.value);
    this.formControl.setValue('');
    this.localesChanged.emit(this.locales);
  }


  public removeLocale(toRemoveLocale: string) {
    this.locales = this.locales.filter(locale => locale !== toRemoveLocale);
    this.localesChanged.emit(this.locales);
  }

  private initFilter() {
    this.filteredOptions = this.formControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
  }

  ngOnInit() {
    this.initFilter();
  }

  ngOnChanges(changes) {
    if (changes['locales'] instanceof SimpleChange) {
      this.initFilter();
    }
  }

}
