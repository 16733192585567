import { Component } from '@angular/core';
import { APP_CONSTANTS } from '../../app.constants';
import * as config from '../../../config/config.json';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent {

  constructor() { }

  public appName = APP_CONSTANTS.NAME;
  public appVersion = APP_CONSTANTS.VERSION;
  public docsUrl = config.api.docs;

}
