import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditLogsComponent } from './audit-logs.component';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSortModule, MatTableModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { AuditLogService } from './service/audit-log.service';
import { AuditLogsDialogModule } from './audit-logs-dialog/audit-logs-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatBadgeModule,
    MatTooltipModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatAutocompleteModule,
    AuditLogsDialogModule
  ],
  declarations: [AuditLogsComponent],
  exports: [AuditLogsComponent],
  providers: [AuditLogService]
})
export class AuditLogsModule { }
