import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-add-tenant-dialog',
  templateUrl: './add-tenant-dialog.html'
})
export class AddTenantDialogComponent {

  public tenantName: string;

  constructor(
    public dialogRef: MatDialogRef<AddTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && !data.isDefaultSet) {
      this.tenantName = 'default';
    }
  }

  public isTenantNameValid(tenantName: string) {
    const regExp = new RegExp('^(?!.*\/).*$');
    return tenantName !== '' && regExp.test(tenantName);
  }

  public addTenant() {
    this.dialogRef.close(this.tenantName);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
