import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSort, MatTableDataSource } from '@angular/material';
import { IAuditLog } from './interfaces/audit-log.interface';
import { Title } from '@angular/platform-browser';
import { AuditLogService } from './service/audit-log.service';
import * as moment from 'moment';
import { AuditLogsDialogComponent } from './audit-logs-dialog/audit-logs-dialog.component';
import { AUDIT_LOGS_CONSTANTS } from './audit-logs.constants';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.css']
})
export class AuditLogsComponent implements OnInit {

  public loading = true;
  public error = false;
  public total = 0;
  public HAS_NO_BODY = AUDIT_LOGS_CONSTANTS.HAS_NO_BODY;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public logs: MatTableDataSource<IAuditLog>;
  public filter = '';
  public displayedColumns: string[] = ['tenant', 'user', 'action', 'timestamp', 'more'];

  constructor(
    private readonly auditLogService: AuditLogService,
    private readonly titleService: Title,
    private readonly dialog: MatDialog
  ) { }

  public filterLogs(filter: string) {
    this.logs.filter = filter.trim().toLowerCase();
  }

  public formatTimeStamp (timestamp: string): string {
    return moment(Number(timestamp)).format('HH:mm:ss, DD.MM.YYYY');
  }

  public clearFilter() {
    this.filter = '';
    this.filterLogs('');
  }

  public openSeeMoreDialog(body: string | undefined) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = body;

    this.dialog.open(AuditLogsDialogComponent, dialogConfig);
  }

  private getLogs() {
    this.auditLogService.getLogs().subscribe(data => {
      this.logs = new MatTableDataSource(data.items);
      setTimeout(() => this.logs.sort = this.sort);
      this.total = data.total;
      this.loading = false;
      this.error = false;
    }, () => {
      this.loading = false;
      this.error = true;
    });
  }

  ngOnInit() {
    this.getLogs();
    this.titleService.setTitle('Audit Logs');
  }
}
