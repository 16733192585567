import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-audit-logs-dialog',
  templateUrl: './audit-logs-dialog.component.html'
})
export class AuditLogsDialogComponent {

  public body: string | undefined;
  public mode = 'json';
  public theme = 'eclipse';

  private formatJsonString(string): string {
    const json = JSON.parse(string);
    return JSON.stringify(json, null, 4);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.body = data ? this.formatJsonString(data) : data;
  }

}
