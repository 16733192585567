import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TenantContactFormComponent} from './tenant-contact-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTableModule
} from '@angular/material';
import { EditTenantContactDialogComponent } from './edit-tenant-contact-dialog/edit-tenant-contact-dialog.component';
import { AddTenantContactDialogComponent } from './add-tenant-contact-dialog/add-tenant-contact-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule
  ],
  declarations: [
    TenantContactFormComponent,
    AddTenantContactDialogComponent,
    EditTenantContactDialogComponent
  ],
  entryComponents: [
    AddTenantContactDialogComponent,
    EditTenantContactDialogComponent
  ],
  exports: [
    TenantContactFormComponent
  ]
})
export class TenantContactFormModule { }
