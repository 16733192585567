import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditLogsDialogComponent } from './audit-logs-dialog.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { AceEditorModule } from 'ng2-ace-editor';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    AceEditorModule
  ],
  declarations: [AuditLogsDialogComponent],
  exports: [AuditLogsDialogComponent],
  entryComponents: [
    AuditLogsDialogComponent
  ],
})
export class AuditLogsDialogModule { }
