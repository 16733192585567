import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { ISocialLinksItem } from '../../interfaces/tenant.interface';
import {
  AddTenantSocialMediaLinkDialogComponent
} from './add-tenant-social-media-link-dialog/add-tenant-social-media-link-dialog.component';
import {
  EditTenantSocialMediaLinkDialogComponent
} from './edit-tenant-social-media-link-dialog/edit-tenant-social-media-link-dialog.component';
import { saveCopyObject } from '../../../shared/helper/deep-merge.helper';

@Component({
  selector: 'app-tenant-social-media-link-form',
  templateUrl: './tenant-social-media-link-form.component.html',
  styleUrls: ['./tenant-social-media-link-form.component.css']
})
export class TenantSocialMediaLinkFormComponent implements OnChanges, OnInit {

  public tenantSocialMediaLinksDataSource: MatTableDataSource<ISocialLinksItem>;
  public displayedColumns = ['locale', 'socialMediaLinks' , 'action'];

  constructor(private readonly dialog: MatDialog) {}

  @Input() defaultLocale = '';
  @Input() locales: string[] = [];
  @Input() socialMediaLinks: ISocialLinksItem[] = [];

  @Output() socialMediaLinksChanged = new EventEmitter();

  private currentLegalLocales: string[];
  public areLocalesMissing = true;

  public setCurrentLocales() {
    this.currentLegalLocales = this.socialMediaLinks && this.socialMediaLinks.length ?
      this.socialMediaLinks.map(item => item.locale) : [];
    this.areLocalesMissing = this.locales && this.locales.length ?
      this.locales.filter(local => this.currentLegalLocales.indexOf(local) === -1).length > 0 : true;
  }

  private refreshTable(): void {
    this.tenantSocialMediaLinksDataSource = new MatTableDataSource(this.socialMediaLinks || []);
    this.setCurrentLocales();
  }

  public openAddDialog(): void {
    const dialogConfig = new MatDialogConfig();
    const selectableLocales = this.locales ?
      this.locales.filter(local => this.currentLegalLocales.indexOf(local) === -1) : [];
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { selectableLocales };

    this.dialog
      .open(AddTenantSocialMediaLinkDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
      if (result) {
        this.socialMediaLinks.push(result);
        this.socialMediaLinksChanged.emit(this.socialMediaLinks);
        this.refreshTable();
      }
    });
  }

  public openEditDialog(socialMediaLinkItem: ISocialLinksItem): void {
    const dialogConfig = new MatDialogConfig();
    const index = this.socialMediaLinks.indexOf(socialMediaLinkItem);
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { socialMediaLinks: saveCopyObject(socialMediaLinkItem)};

    this.dialog
      .open(EditTenantSocialMediaLinkDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
        if (result) {
          this.socialMediaLinks[index] = result;
          this.socialMediaLinksChanged.emit(this.socialMediaLinks);
          this.refreshTable();
        }
    });
  }

  public deleteContact(socialMediaLinkItem: ISocialLinksItem) {
    this.socialMediaLinks = this.socialMediaLinks && this.socialMediaLinks.length ?
      this.socialMediaLinks.filter(item => item.locale !== socialMediaLinkItem.locale) : this.socialMediaLinks;
    this.socialMediaLinksChanged.emit(this.socialMediaLinks);
    this.refreshTable();
  }

  ngOnChanges(changes) {
    if (changes['socialMediaLinks'] instanceof SimpleChange) {
      this.refreshTable();
    } else if (changes['locales'] instanceof SimpleChange || changes['defaultLocale'] instanceof SimpleChange ) {
      this.setCurrentLocales();
    }
  }

  ngOnInit() {
    this.refreshTable();
  }

}
