import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ITenant } from './interfaces/tenant.interface';
import { TenantService } from './service/tenant.service';
import { SnackbarService } from '../shared/service/snackbar.service';
import { DeleteTenantDialogComponent } from './delete-tenant-dialog/delete-tenant-dialog';
import { AddTenantDialogComponent } from './add-tenant-dialog/add-tenant-dialog';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements OnInit {

  public loading = true;
  public error = false;
  public hasData = false;
  public isDefaultSet = false;
  public total = 0;

  public tenants: MatTableDataSource<ITenant>;

  public displayedColumns: Array<string> = ['id', 'theme', 'defaultLocale', 'action'];

  constructor(
    private readonly tenantService: TenantService,
    private readonly snackbarService: SnackbarService,
    private readonly dialog: MatDialog,
    private readonly titleService: Title
  ) { }

  private checkIfDefaultIsSet(items) {
    return items.some((el) => el.id.toLowerCase() === 'default');
  }

  private setTenants() {
    this.tenantService.getTenants().subscribe((data) => {
      this.tenants = new MatTableDataSource(data.items);
      this.total = data.total;
      this.isDefaultSet = this.checkIfDefaultIsSet(data.items);
      this.loading = false;
      this.hasData = data.items && data.items.length > 0;
      this.error = false;
    }, () => {
      this.loading = false;
      this.hasData = true;
      this.error = true;
    });
  }

  public filterTenants(filter: string) {
    this.tenants.filter = filter.trim().toLowerCase();
  }

  private tenantAlreadyExists(tenant): boolean {
    return this.tenants && this.tenants. data && this.tenants.data.some(t => t.id === tenant);
  }

  public openAddDialog(): void {
    const dialogRef = this.dialog.open(AddTenantDialogComponent, {data: {isDefaultSet: this.isDefaultSet}});
    dialogRef.afterClosed().subscribe(tenantId => {
      if (tenantId) {
        if (this.tenantAlreadyExists(tenantId)) {
          this.snackbarService.open(`${tenantId} already exists`);
        } else {
          this.loading = true;
          const tenant = {...this.tenantService.getTenantExample(), ...{id: tenantId}};
          this.tenantService.addTenant(tenant).subscribe(() => {
            this.snackbarService.open(`${tenant} was added`);
            this.setTenants();
          }, () => {
            this.snackbarService.open(`${tenant} cannot be added`);
          });
        }
      }
    });
  }

  public openDeleteDialog(tenant: ITenant) {
    const dialogRef = this.dialog.open(DeleteTenantDialogComponent, {data: {tenant} });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.tenantService.deleteTenant(result).subscribe(() => {
          this.setTenants();
          this.snackbarService.open(`${result.id} was deleted`);
        }, () => {
          this.snackbarService.open(`${result.id} cannot be deleted`);
        });
      }
    });
  }

  ngOnInit() {
    this.setTenants();
    this.titleService.setTitle('Tenant List');
  }

}
