import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { IContactItem } from '../../../interfaces/tenant.interface';
import { TenantContactExample } from '../../../service/tenant.examples';
import { saveCopyObject } from '../../../../shared/helper/deep-merge.helper';

@Component({
  selector: 'app-add-tenant-contact-dialog',
  templateUrl: './add-tenant-contact-dialog.component.html'
})
export class AddTenantContactDialogComponent {

  public selectableLocales: string[];
  public contact: IContactItem = saveCopyObject(TenantContactExample);

  constructor(
    public dialogRef: MatDialogRef<AddTenantContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectableLocales = data.selectableLocales;
    this.contact.locale = data.selectableLocales[0];
  }

  public addContact() {
    this.dialogRef.close(this.contact);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
