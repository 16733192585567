import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-edit-tenant-contact-dialog',
  templateUrl: './edit-tenant-contact-dialog.component.html'
})
export class EditTenantContactDialogComponent {

  public contact: any;

  constructor(
    public dialogRef: MatDialogRef<EditTenantContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.contact = data.contact;
  }

  public updateContact() {
    this.dialogRef.close(this.contact);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
