import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { IContactItem, ISocialLinksItem } from '../../../interfaces/tenant.interface';
import { TenantContactExample, TenantSocialLinksExample } from '../../../service/tenant.examples';
import { saveCopyObject } from '../../../../shared/helper/deep-merge.helper';

@Component({
  selector: 'app-add-tenant-social-media-link-dialog',
  templateUrl: './add-tenant-social-media-link-dialog.component.html'
})
export class AddTenantSocialMediaLinkDialogComponent {

  public selectableLocales: string[];
  public socialMediaLinks: ISocialLinksItem = saveCopyObject(TenantSocialLinksExample);

  constructor(
    public dialogRef: MatDialogRef<AddTenantSocialMediaLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectableLocales = data.selectableLocales;
    this.socialMediaLinks.locale = data.selectableLocales[0];
  }

  public addSocialMediaLinks() {
    this.dialogRef.close(this.socialMediaLinks);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

}
