import {
  ILegalItem,
  ITenant,
  IContactItem,
  ISocialLinksItem,
} from '../interfaces/tenant.interface';
import { LEGAL_VALUE_TYPE_LINK } from '../tenant-details/tenant-legal-form/tenant-legal-form.constants';

export const TenantLegalUnitExample: ILegalItem = {
  locale: '',
  type: LEGAL_VALUE_TYPE_LINK,
  value: '',
};

export const TenantContactExample: IContactItem = {
  locale: '',
  telephone: '',
  fax: '',
  email: '',
  street: '',
  zipCode: '',
  city: '',
  helpLink: '',
};

export const TenantSocialLinksExample: ISocialLinksItem = {
  locale: '',
  facebook: '',
  instagram: '',
  xing: '',
  linkedIn: '',
};

export const TenantExample: ITenant = {
  id: '',
  company: '',
  locales: [],
  socialMediaLinks: [],
  theme: {
    id: '',
    version: '',
  },
  legal: {
    imprint: [],
    privacy: [],
    termsOfUse: [],
    contact: [],
  },
  defaults: {
    locale: '',
    energyManagerName: '',
    centricsId: '',
  },
};
