import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChange } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { EditTenantLegalDialogComponent } from './edit-tenant-legal-dialog/edit-tenant-legal-dialog.component';
import { ILegalItem } from '../../interfaces/tenant.interface';
import { AddTenantLegalDialogComponent } from './add-tenant-legal-dialog/add-tenant-legal-dialog.component';

@Component({
  selector: 'app-tenant-legal-form',
  templateUrl: './tenant-legal-form.component.html',
  styleUrls: ['./tenant-legal-form.component.css']
})
export class TenantLegalFormComponent implements OnInit, OnChanges {

  public tenantLegalDataSource: MatTableDataSource<ILegalItem>;
  public displayedColumns = ['locale', 'type', 'value', 'action'];

  constructor(private readonly dialog: MatDialog) {}

  @Input() label: string;
  @Input() defaultLocale = '';
  @Input() locales: string[] = [];
  @Input() tenantLegal: ILegalItem[] = [];

  @Output() legalChanged = new EventEmitter();

  private currentLegalLocales: string[];
  public areLocalesMissing = true;

  public setCurrentLocales() {
    this.currentLegalLocales = this.tenantLegal && this.tenantLegal.length ?
      this.tenantLegal.map(item => item.locale) : [];
    this.areLocalesMissing = this.locales && this.locales.length ?
      this.locales.filter(local => this.currentLegalLocales.indexOf(local) === -1).length > 0 : true;
  }

  public trimValueString(val: string): string {
   return val.length < 10 ? val : val.substring(0, 10) + '…';
  }

  public openAddDialog(): void {
    const dialogConfig = new MatDialogConfig();
    const selectableLocales = this.locales.length ?
      this.locales.filter(local => this.currentLegalLocales.indexOf(local) === -1) : [];
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { label: this.label, selectableLocales };

    this.dialog
      .open(AddTenantLegalDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
      if (result) {
        this.tenantLegal.push(result);
        this.legalChanged.emit(this.tenantLegal);
        this.refreshTable();
      }
    });
  }

  public openEditDialog(legalItem: ILegalItem): void {
    const dialogConfig = new MatDialogConfig();
    const index = this.tenantLegal.indexOf(legalItem);
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { legalUnit: {...legalItem}, label: this.label };

    this.dialog
      .open(EditTenantLegalDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
      if (result) {
        this.tenantLegal[index] = result;
        this.legalChanged.emit(this.tenantLegal);
        this.refreshTable();
      }
    });
  }

  public deleteLegalItem(legalItem: ILegalItem) {
    this.tenantLegal = this.tenantLegal.length ?
      this.tenantLegal.filter(item => item.locale !== legalItem.locale) : this.tenantLegal;
    this.legalChanged.emit(this.tenantLegal);
    this.refreshTable();
  }

  private refreshTable() {
    this.tenantLegalDataSource = new MatTableDataSource(this.tenantLegal || []);
    this.setCurrentLocales();
  }

  ngOnInit() {
    this.refreshTable();
  }

  ngOnChanges(changes) {
    if (changes['tenantLegal'] instanceof SimpleChange) {
      this.refreshTable();
    }
  }

}
