// Merge a `source` object to a `target` recursively
export const deepMerge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  Object.keys(source).forEach(key => {
    if (source[key] instanceof Object) {
      source[key] = Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  });
  // Join `target` and modified `source`
  return Object.assign(target || {}, source);
};

export const saveCopyObject = (source) => {
  return source ? JSON.parse(
    JSON.stringify(
      source
    )
  ) : source;
};
