import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TenantsComponent} from './tenants.component';
import {
  MatBadgeModule,
  MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSelectModule, MatSnackBarModule, MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeleteTenantDialogComponent} from './delete-tenant-dialog/delete-tenant-dialog';
import {RouterModule} from '@angular/router';
import {TenantDetailsModule} from './tenant-details/tenant-details.module';
import { SnackbarService } from '../shared/service/snackbar.service';
import { TenantService } from './service/tenant.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddTenantDialogComponent } from './add-tenant-dialog/add-tenant-dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatBadgeModule,
    TenantDetailsModule
  ],
  declarations: [
    TenantsComponent,
    AddTenantDialogComponent,
    DeleteTenantDialogComponent,
  ],
  entryComponents: [
    AddTenantDialogComponent,
    DeleteTenantDialogComponent,
  ],
  exports: [
    TenantsComponent
  ],
  providers: [
    TenantService,
    SnackbarService
  ]
})
export class TenantsModule { }
