import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class SnackbarService {

  public snackBarConf: any = { duration: 5000 };
  public snackBarAction = 'Close';

  constructor(private readonly snackBar: MatSnackBar) {}

  public open(message: string) {
    this.snackBar.open(message, this.snackBarAction, this.snackBarConf);
  }

}
