import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantDetailsComponent } from './tenant-details.component';
import { RouterModule } from '@angular/router';
import { MatIconModule, MatTabsModule, MatToolbarModule, MatButtonModule} from '@angular/material';
import { SnackbarService } from '../../shared/service/snackbar.service';
import { TenantService } from '../service/tenant.service';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddLocaleModule } from './add-locale/add-locale.module';
import { TenantLegalFormModule } from './tenant-legal-form/tenant-legal-form.module';
import { TenantContactFormModule } from './tenant-contact-form/tenant-contact-form.module';
import { TenantSocialMediaLinkFormModule } from './tenant-social-media-link-form/tenant-social-media-link-form.module';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatTabsModule,
    MatStepperModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatExpansionModule,
    AddLocaleModule,
    TenantLegalFormModule,
    TenantContactFormModule,
    TenantSocialMediaLinkFormModule
  ],
  declarations: [
    TenantDetailsComponent
  ],
  exports: [
    TenantDetailsComponent
  ],
  providers: [
    TenantService,
    SnackbarService
  ]
})
export class TenantDetailsModule { }
