import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatTableDataSource,
} from '@angular/material';
import { IContactItem } from '../../interfaces/tenant.interface';
import { EditTenantContactDialogComponent } from './edit-tenant-contact-dialog/edit-tenant-contact-dialog.component';
import { AddTenantContactDialogComponent } from './add-tenant-contact-dialog/add-tenant-contact-dialog.component';

@Component({
  selector: 'app-tenant-contact-form',
  templateUrl: './tenant-contact-form.component.html',
  styleUrls: ['./tenant-contact-form.component.css'],
})
export class TenantContactFormComponent implements OnInit, OnChanges {
  public tenantContactDataSource: MatTableDataSource<IContactItem>;
  public displayedColumns = [
    'locale',
    'telephone',
    'fax',
    'email',
    'address',
    'helpLink',
    'action',
  ];

  constructor(private readonly dialog: MatDialog) {}

  @Input() defaultLocale = '';
  @Input() locales: string[] = [];
  @Input() tenantContact: IContactItem[] = [];

  @Output() contactChanged = new EventEmitter();

  private currentLegalLocales: string[];
  public areLocalesMissing = true;

  public setCurrentLocales() {
    this.currentLegalLocales =
      this.tenantContact && this.tenantContact.length
        ? this.tenantContact.map((unit) => unit.locale)
        : [];
    this.areLocalesMissing =
      this.locales && this.locales.length
        ? this.locales.filter(
            (local) => this.currentLegalLocales.indexOf(local) === -1
          ).length > 0
        : true;
  }

  private refreshTable(): void {
    this.tenantContactDataSource = new MatTableDataSource(
      this.tenantContact || []
    );
    this.setCurrentLocales();
  }

  public openAddDialog(): void {
    const dialogConfig = new MatDialogConfig();
    const selectableLocales = this.locales.length
      ? this.locales.filter(
          (local) => this.currentLegalLocales.indexOf(local) === -1
        )
      : [];
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { selectableLocales };

    this.dialog
      .open(AddTenantContactDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.tenantContact.push(result);
          this.contactChanged.emit(this.tenantContact);
          this.refreshTable();
        }
      });
  }

  public openEditDialog(contact: IContactItem): void {
    const dialogConfig = new MatDialogConfig();
    const index = this.tenantContact.indexOf(contact);
    dialogConfig.panelClass = 'mat-dialog-full-screen';
    dialogConfig.data = { contact: { ...contact } };

    this.dialog
      .open(EditTenantContactDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.tenantContact[index] = result;
          this.contactChanged.emit(this.tenantContact);
          this.refreshTable();
        }
      });
  }

  public deleteContact(contact: IContactItem) {
    this.tenantContact = this.tenantContact.length
      ? this.tenantContact.filter((item) => item.locale !== contact.locale)
      : this.tenantContact;
    this.contactChanged.emit(this.tenantContact);
    this.refreshTable();
  }

  ngOnChanges(changes) {
    if (changes['tenantContact'] instanceof SimpleChange) {
      this.refreshTable();
    } else if (
      changes['locales'] instanceof SimpleChange ||
      changes['defaultLocale'] instanceof SimpleChange
    ) {
      this.setCurrentLocales();
    }
  }

  ngOnInit() {
    this.refreshTable();
  }
}
