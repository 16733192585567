import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterBarComponent} from './footer-bar.component';
import {MatIconModule, MatToolbarModule, MatTooltipModule, MatButtonModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule
  ],
  declarations: [
    FooterBarComponent
  ],
  exports: [
    FooterBarComponent
  ]
})
export class FooterBarModule { }
