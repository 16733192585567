import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TenantLegalFormComponent} from './tenant-legal-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule,
  MatRadioModule,
  MatSelectModule, MatTableModule, MatTooltipModule
} from '@angular/material';
import { AceEditorModule } from 'ng2-ace-editor';
import { EditTenantLegalDialogComponent } from './edit-tenant-legal-dialog/edit-tenant-legal-dialog.component';
import { AddTenantLegalDialogComponent } from './add-tenant-legal-dialog/add-tenant-legal-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    AceEditorModule
  ],
  declarations: [
    TenantLegalFormComponent,
    AddTenantLegalDialogComponent,
    EditTenantLegalDialogComponent
  ],
  entryComponents: [
    AddTenantLegalDialogComponent,
    EditTenantLegalDialogComponent
  ],
  exports: [
    TenantLegalFormComponent
  ]
})
export class TenantLegalFormModule { }
